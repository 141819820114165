import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import PageTitle from "components/PageTitle";
import CategoriesGridList from "components/CategoriesGridList";

import CategoriesItem from "pagesComponents/Categories/CategoriesItem";
import PrivateLayout from "layouts/PrivateLayout";

const CategoriesPage = ({ data }) => (
  <PrivateLayout metaTitle="Categorías">
    <PageTitle title="Categorías" />
    <CategoriesGridList itemWidth="500px">
      {data.allContentfulCategory.edges.map(({ node }) => (
        <CategoriesItem
          key={node.slug}
          {...node}
          image={<Img fixed={node.image.fixed} />}
        />
      ))}
    </CategoriesGridList>
  </PrivateLayout>
);

CategoriesPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default CategoriesPage;

export const query = graphql`
  query {
    allContentfulCategory(sort: { order: DESC, fields: parsedUpdatedAt }) {
      edges {
        node {
          title
          slug
          image {
            fixed(height: 480, quality: 100) {
              ...GatsbyContentfulFixed_withWebp
            }
          }
        }
      }
    }
  }
`;
